<template>
  <div class="text-center">
    <h2 class="h2 my-5">廠牌類別</h2>
    <div class="table-responsive-xl">
      <table class="table table-hover mx-auto" style="width: 600px">
        <thead class="thead-dark">
          <tr class="text-left">
            <th>名稱</th>
            <th width="150">路徑</th>
            <th colspan="2" class="text-center">操作</th>
          </tr>
        </thead>
        <tbody>
          <template v-for="item in brand" :key="item.id">
            <tr class="text-left">
              <td class="align-middle">
                <input type="text" v-model="item.name" class="form-control" />
              </td>
              <td class="align-middle" width="150">
                <input type="text" class="form-control" v-model="item.path" />
              </td>
              <td class="align-middle" width="100">
                <button
                  type="button"
                  class="btn btn-outline-danger"
                  @click="openModal('edit', item)"
                >
                  修改
                </button>
              </td>
              <td class="align-middle" width="100">
                <button
                  type="button"
                  class="btn btn-outline-danger"
                  @click="openModal('remove', item)"
                >
                  刪除
                </button>
              </td>
            </tr>
          </template>
        </tbody>
      </table>
    </div>

    <h2 class="h2 mt-5">新增類別</h2>
    <div class="table-responsive-xl">
      <table class="table table-hover mt-5 mx-auto" style="width: 600px">
        <thead class="thead-dark">
          <tr>
            <th>類別名稱</th>
            <th>類別路徑</th>
            <th width="100"></th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              <input
                type="text"
                class="form-control"
                placeholder="請輸入類別名稱"
                v-model="name"
              />
            </td>
            <td>
              <input
                type="text"
                class="form-control"
                placeholder="請輸入類別路徑(英文)"
                v-model="path"
              />
            </td>
            <td width="100">
              <button
                type="button"
                class="btn btn-danger"
                @click="createBrand"
                :disabled="name.trim() === '' || path.trim() === ''"
              >
                新增
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
  <Modal @submit="submit">
    <!-- title -->
    <template v-slot:title>
      <div v-if="modalType === 'edit'">修改</div>
      <div v-if="modalType === 'remove'">刪除</div>
    </template>
    <template v-slot:body>
      <div v-if="modalType === 'edit'">
        是否要修改成 <br />
        名稱 {{ temp.name }} <br />
        路徑 {{ temp.path }}
      </div>
      <div v-if="modalType === 'remove'">是否要刪除 {{ temp.name }}</div>
    </template>

    <!-- body -->
  </Modal>
</template>

<script>
import $ from "jquery";
import Modal from "components/back/Modal";
import { ref, onMounted, inject } from "vue";
import { create, fetchBrand, edit, remove } from "connection/admin/brandDB";
export default {
  name: "brand",
  components: {
    Modal,
  },
  setup() {
    const mitt = inject("mitt");
    const name = ref("");
    const path = ref("");
    const brand = ref([]);
    const getBrand = async () => {
      try {
        let res = await fetchBrand();
        brand.value = res;
      } catch (error) {
        console.log(error);
      }
    };
    onMounted(() => {
      getBrand();
    });
    //create
    const createBrand = async () => {
      try {
        await create(name.value, path.value);
        name.value = "";
        path.value = "";
        getBrand();
      } catch (error) {
        console.log(error);
      }
    };
    //edit
    const editBrand = async (item) => {
      try {
        await edit(item);
        getBrand();
        mitt.emit("alert", { state: "success", message: "廠牌已修改" });
      } catch (error) {
        console.log(error);
      }
    };
    //remove
    const removeBrand = async (id) => {
      try {
        await remove(id);
        getBrand();
        mitt.emit("alert", { state: "success", message: "廠牌已刪除" });
      } catch (error) {
        console.log(error);
      }
    };

    const temp = ref({});
    const modalType = ref("");
    const openModal = (type, item) => {
      modalType.value = type;
      Object.assign(temp.value, item);
      $("#Modal").modal("show");
    };
    const submit = () => {
      if (modalType.value === "edit") {
        editBrand(temp.value);
      } else if (modalType.value === "remove") {
        removeBrand(temp.value.id);
      }
      $("#Modal").modal("hide");
    };
    return {
      brand,
      name,
      path,
      createBrand,
      editBrand,
      removeBrand,
      temp,
      modalType,
      openModal,
      submit,
    };
  },
};
</script>
